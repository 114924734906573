import { render, staticRenderFns } from "./brandThing.vue?vue&type=template&id=33b4a402&scoped=true&"
import script from "./brandThing.vue?vue&type=script&lang=js&"
export * from "./brandThing.vue?vue&type=script&lang=js&"
import style0 from "./brandThing.vue?vue&type=style&index=0&id=33b4a402&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b4a402",
  null
  
)

export default component.exports